<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <!-- Edited users-->
            <v-card-text>
                <v-form v-model="isValid">
                    <v-container class="py-0">
                        <div v-if="item">
                            <div>Nombre: <strong>{{ item.name }}</strong></div>
                            <div>Correo: <strong>{{ item.email }}</strong></div>
                        </div>

                        <v-row v-else>
                            <v-col
                                cols="12"
                                sm="12"
                            >
                                <!--                              :disabled="isOnlyView"-->

                                <v-autocomplete
                                    v-model="editedItem.id"
                                    :items="players"
                                    :label="'Usuarios que no estan en el juego'"
                                    item-text="username"
                                    item-value="id"
                                    :rules="[rules.required]"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                                sm="12"
                            >
                                <!--                              :disabled="isOnlyView"-->

                                <v-select
                                    v-model="editedItem.team.value"
                                    :error-messages="editedItem.team.error"
                                    :items="getTeams"
                                    :label="'Equipos'"
                                    item-text="name"
                                    item-value="id"

                                    :rules="[rules.required]"
                                    @change="editedItem.team.error = ''"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                    {{ $t('common.confirmDialogCancel') }}
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!isValid && !serverCheck"
                    @click="save"
                >
                    {{ $t('common.dialogSave') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from '@/plugins/axios'
import rules from '@/helpers/rulesValidate'
import { mapGetters } from 'vuex'
// import GroupsSelect from '@/components/admin/groups/groupsSelect'

export default {
    // components: { GroupsSelect },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        gameId: {
            type: Number,
            required: true
        },
        teams_count: {
            type: Number,
            required: true
        },
        item: {
            type: Object,
            required: false
        }
    },
    data: function () {
        return {
            urlMain: 'admin/games/players',
            rules: {
                ...rules
            },
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            players: []
        }
    },

    computed: {
        getTeams() {
            return Array(this.teams_count).fill(1).map((item, index) => ({ id: index + 1, name: `Equipo ${index + 1}` }));
        },
        formTitle() {
            return this.item
                ? this.$t('admin.users.edit-user')
                : this.$t('admin.users.new-user')
        }
    },
    created() {
        if (!this.item) {
            this.fetchPlayersNotInGame();
        }
    },
    methods: {
        getEditedItem() {
            const item = this.item

            var empty = !item

            return {
                id: empty ? '' : item.id,
                /* name: {
                    value: empty ? '' : item.name,
                    error: ''
                },
                lastname: {
                    value: empty ? '' : item.lastname,
                    error: ''
                },
                username: {
                    value: empty ? '' : item.username,
                    error: ''
                },
                email: {
                    value: empty ? '' : item.email,
                    error: ''
                }, */

                team: {
                    value: empty ? '' : item?.pivot?.team ?? '',
                    error: ''
                }
            }
        },
        close() {
            this.$emit('update:dialog', false)
        },

        async save() {
            var dataSubmit = {
                id: '' + this.editedItem.id,
                game: this.gameId,
                team: this.editedItem.team.value
            }

            const urlAppend = '/' + this.editedItem.id

            let method = 'post'
            if (this.item) {
                method = 'patch'
            }

            try {
                const result = await axios({
                    method: method,
                    url: this.urlMain + urlAppend,
                    data: dataSubmit
                })

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.close()

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                }
            } catch (error) {
                if (error.response.status === 422) {
                    if (error.response?.data?.errors?.length) {
                        error.response.data.errors.forEach((e) => {
                            if (e.source.pointer === '/data/attributes/email') {
                                this.editedItem.email.error = e.detail
                            }
                            if (e.source.pointer === '/data/attributes/name') {
                                this.editedItem.name.error = e.detail
                            }
                            if (e.source.pointer === '/data/attributes/lastname') {
                                this.editedItem.lastname.error = e.detail
                            }
                            if (e.source.pointer === '/data/attributes/username') {
                                this.editedItem.username.error = e.detail
                            }
                        })
                    }
                }

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }

            this.$emit('update:table')
        },
        async fetchPlayersNotInGame() {
            try {
                const result = await axios
                    .get(`${this.urlMain}/notin/${this.gameId}`);

                this.players = result.data.players;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        }
    }
}
</script>
