<template>
    <v-dialog
        v-model="dialog"
        :max-width="options.width"
        :style="{ zIndex: options.zIndex,}"
        @keydown.esc="cancel"
    >
        <v-card>
            <!--          :color="options.color"-->
            <v-card-title class="text-h5">
                {{ title }}
            </v-card-title>

            <v-card-text
                v-show="!!message"
                class="pa-4 black--text"
                v-html="message"
            />

            <v-card-actions class="pt-3 d-flex flex-wrap flex-column">
                <v-btn
                    v-for="(item, index) in options.buttons"
                    :key="index"
                    :color="item.color"
                    text
                    :outlined="index === options.buttons.length - 1"
                    class="text-body-2 font-weight-bold"
                    @click.native="select(item)"
                    v-text="item.text"
                />
                <!--                <v-btn
                    v-if="!options.noOk"
                    color="primary"
                    class="text-body-2 font-weight-bold"
                    outlined
                    @click.native="agree"
                >
                    {{ $t('common.confirmDialogOk') }}
                </v-btn>-->
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "OptionSelectDialog",
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            options: {
                buttons: [
                    {
                        text: "Opción 1",
                        color: "primary",
                        value: "1"
                    },
                    {
                        text: "Opción 2",
                        color: "primary",
                        value: "2"
                    }
                ],

                width: 400,
                zIndex: 200
            }
        };
    },

    methods: {
        open(title, message, options = null) {
            /* const width = this.$vuetify.breakpoint.width;
            this.options.width = width > 400 ? 400 : width - 10; */
            // console.log('width ', width)

            this.dialog = true;
            this.title = title;
            this.message = message;

            let buttons = [];

            if (options?.buttons && options.buttons instanceof Array) {
                buttons = options.buttons.map((btn, index) => ({
                    text: btn.text || `Opción ${index + 1}`,
                    color: btn.color || "primary",
                    value: btn.value || `${index + 1}`
                }));
            }

            this.options = Object.assign(this.options, options, { buttons });
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        select(item) {
            this.resolve(item.value || item.text);
            this.dialog = false;
        },
        cancel() {
            this.resolve(null);
            this.dialog = false;
        }
    }
};
</script>
<style>
.v-dialog.v-dialog--active{
 border-radius: 8px;
}
</style>
